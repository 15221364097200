'use client';

import { useLocalStorageValue } from '@react-hookz/web';
import { useTranslations } from 'next-intl';

import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@zealy/design-system';

import { useIsBrave } from '#hooks/useIsBrave';

export const BraveShieldDialog = () => {
  const isBrave = useIsBrave();
  const t = useTranslations('brave');

  const { value: show, set } = useLocalStorageValue('braveShieldDialog:show', {
    defaultValue: true,
  });

  if (!isBrave) return null;

  return (
    <Dialog open={show} onOpenChange={() => set(false)}>
      <DialogContent
        className="text-left md:px-500 py-400 border-none bg-tertiary max-w-2xl h-full lg:h-auto"
        style={{ zIndex: 60 }}
      >
        <DialogHeader className="border-b pb-200 border-border">
          <DialogTitle className="text-left">{t('shield.title')}</DialogTitle>
        </DialogHeader>
        <div>
          <p className="text-secondary body-paragraph-lg">{t('shield.content')}</p>

          <video className="mt-400 rounded-component-md px-100 h-[324px]" controls>
            <source
              src="https://zealy-webapp-images-prod.s3.eu-west-1.amazonaws.com/public/users/84879616-9d00-44a1-b6dd-2a1ba6f3b7bb/31cbbfcf-4766-49f6-8c59-dfeb49ab6f08-CleanShot2024-11-19at085812.mp4"
              type="video/mp4"
            />
            <meta itemProp="description" content={t('shield.title')}></meta>
          </video>

          <DialogFooter className="flex lg:items-end pt-400">
            <Button variant="filled" color="cta" onClick={() => set(false)}>
              {t('shield.cta')}
            </Button>
          </DialogFooter>
        </div>
      </DialogContent>
    </Dialog>
  );
};
