type NavigatorWithBrave = Navigator & {
  brave?: {
    isBrave: () => Promise<boolean>;
  };
};

export const isBrave = async () => {
  const nav = navigator as NavigatorWithBrave;
  return (nav.brave && (await nav.brave.isBrave())) ?? false;
};
