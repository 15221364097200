import { useEffect, useState } from 'react';

import { isBrave } from '#utils/isBrave';

export const useIsBrave = () => {
  const [isBraveBrowser, setIsBraveBrowser] = useState<boolean>(false);

  useEffect(() => {
    isBrave().then(setIsBraveBrowser);
  }, []);

  return isBraveBrowser;
};
